import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/Modal';
import './style.css';

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			error: false,
			next: false,
			isLoading: false,
			isOpen: false,
			user: false,
			posts: []
		}
		window.onscroll = () => {
			const {
				state: {
					error,
					next,
					isLoading
				}
			} = this;
			if ( error || isLoading || !next ) return;
			if (
				window.innerHeight + document.documentElement.scrollTop
				=== document.documentElement.offsetHeight
			){
				this.loadMore()
			}
		}
	}
	componentDidMount = () => {
		this.setState({ isLoading: true }, async () => {
			const data = JSON.parse(document.getElementsByTagName("script")[0].textContent);
			this.setState({
				isLoading: false,
				next: ( data.media.next || false ),
				user: {
					image_src: data.image.src,
					full_name: data.full_name,
					id: data.id,
					media_count: data.media.count + ( data.media.count === 1 ? " post" : " posts" ),
					followed_by_count: data.followed_by.count + ( data.followed_by.count === 1 ? " follower" : " followers" ),
					follow_count: data.follow.count + " following",
					username: data.username
				},
				posts: [
					...this.state.posts,
					...data.media.items.map(post => ({
						shortcode: post.shortcode,
						thumbnail_src: post.thumbnail.src,
						image_src: post.image.src,
						image_alt: post.image.alt,
						location_name: ( post.location.name ? post.location.name : "Somewhere" ),
						caption_text: post.caption.text,
						date: post.date
					}))
				]
			})
			document.title = data.full_name
		})
	}
	loadMore = () => {
		this.setState({ isLoading: true }, async () => {
			fetch(Buffer.from(this.state.next, 'base64').toString('ascii'))
				.then(response => response.json())
				.then(data => this.setState({
					isLoading: false,
					next: ( data.media.next || false ),
					posts: [
						...this.state.posts,
						...data.media.items.map(post => ({
							shortcode: post.shortcode,
							thumbnail_src: post.thumbnail.src,
							image_src: post.image.src,
							image_alt: post.image.alt,
							location_name: ( post.location.name ? post.location.name : "Somewhere" ),
							caption_text: post.caption.text,
							date: post.date
						}))
					]
				}));
		})
	}
	FormattedDate = (e) => {
		const month = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
		const date = new Date( e * 1000 )
		return month[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
	}
	handleModalShow = (e, index) => {
		this.setState({ isOpen: index });
	}
	handleModalClose = () => {
		this.setState({ isOpen: false });
	}
	render() {
		const {
			state: {
				user,
				posts
			}
		} = this
		return (
			<article>
				<div className="jumbotron bg-transparent px-0 mb-0">
					<div className="container">
						<div className="card bg-transparent border-0 mb-1 mb-sm-2">
							<div className="row no-gutters">
								<div className="col-3 col-sm-2">
									<img src={ user.image_src } alt="" className="card-img border border-white rounded-circle" />
								</div>
								<div className="col-9 col-sm-6">
									<div className="card-body pl-3 pr-0 py-0 p-sm-3 ml-sm-3">
										<h1 className="card-title">{ user.full_name }</h1>
										<ul className="list-group list-group-horizontal mb-2">
											<li className="list-group-item bg-transparent border-0 pt-0 pl-0 pr-2 text-muted">{ user.media_count }</li>
											<li className="list-group-item bg-transparent border-0 pt-0 px-2 text-muted">{ user.followed_by_count }</li>
											<li className="list-group-item bg-transparent border-0 pt-0 px-2 text-muted">{ user.follow_count }</li>
										</ul>
										<a href={ "https://www.instagram.com/" + user.username + "/" } role="button" className="btn btn-dark">Follow @{ user.username } on Instagram</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row row-cols-1 row-cols-sm-3">
						{ posts.map((post, index) => (
							<div className="col px-0 px-sm-3 pb-4" key={ post.shortcode }>
								<div className="card bg-transparent border-0 h-100">
									<a onClick={ e => this.handleModalShow(e, index) } href={ "#" + post.shortcode } data-toggle="modal" data-target={ "#" + post.shortcode }>
										<img src={ post.thumbnail_src } alt={ post.alt } className="card-img-top" />
									</a>
									<div className="card-body">
										<h5 className="card-title mb-2">{ post.location_name }</h5>
										<p className="card-text mb-1">{ post.caption_text }</p>
										<p className="card-text">
											<small className="text-muted">{ this.FormattedDate(post.date) }</small>
										</p>
									</div>
								</div>
							</div>
						)) }
					</div>
				</div>
				{ posts.map((post, index) => (
					<Modal size="xl" centered show={ this.state.isOpen === index } onHide={ this.handleModalClose } key={ index }>
						<div className="card bg-dark">
							<div className="row no-gutters">
								<div className="col-sm-8">
									<img src={ post.image_src } alt={ post.alt } className="card-img" />
								</div>
								<div className="col-sm-4">
									<div className="card-body">
										<h5>{ post.location_name }</h5>
										<p className="mb-1">{ post.caption_text }</p>
										<p className="mb-1">{ this.FormattedDate(post.date) }</p>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				)) }
			</article>
		)
	}
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
